import React, { useState } from "react";
import "./App.css";
import LoginSignUpFix from "./Components/loginSignupFix";
import Dashboard from "./Components/dashboard/dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LinkedIn from "./Components/linkedin";
// import InstagramRedirect from "./Components/instagramRedirect";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/linkedin" exact element={<LinkedIn />} />
          {/* <Route path="/instagram" exact element={<InstagramRedirect />} /> */}

          <Route path="/" exact element={<HomePage />} />
          <Route path="/loginnSignup" element={<LoginSignUpFix />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
