import React from "react";
import "./style.css";

function RadioButton({ labels = [], onChange, defaultCheckedId }) {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {labels.map((l) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value={l.id}
              id={l.id}
              name="radio"
              onClick={(e) => {
                onChange(e.target.value);
              }}
              defaultChecked={defaultCheckedId === l.id}
              className="input-hover"
              style={{ width: "15px", height: "15px" }}
            />
            <label className="radio-label" for={l.id}>
              {l.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default RadioButton;
