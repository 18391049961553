// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-label {
  color: var(--gray100);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  /* margin: 0 10px; */
}
.radio-label a {
  color: var(--Gray-400, #909498);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: var(--primary);
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/radioButton/style.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,oBAAoB;AACtB;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,kBAAkB;AACpB","sourcesContent":[".radio-label {\n  color: var(--gray100);\n  font-family: Inter;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.4px;\n  /* margin: 0 10px; */\n}\n.radio-label a {\n  color: var(--Gray-400, #909498);\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  text-decoration-line: underline;\n}\n\ninput[type=\"checkbox\"] {\n  width: 18px;\n  height: 18px;\n  accent-color: var(--primary);\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
